import dayjs from "dayjs";

export const MOCK_BLOG = {
  id: "111",
  url: "https://www.google.com",
  name: "Name",
  title: "จริงมั้ย? ช่วยตัวเองบ่อยๆ ทำให้หัวล้าน",
  tags: [],
  shortDescription:
    "หากคุณเป็นนักกีฬาวิ่ง การได้ทะยานเข้าสู่เส้นชัย ก่อนผู้เข้าแข่งขัน คนอื่นๆ ย่อมเป็นสิ่งที่ถวิลหา และเป็นชัยชนะที่รอคอย",
  description:
    "หากคุณเป็นนักกีฬาวิ่ง การได้ทะยานเข้าสู่เส้นชัย ก่อนผู้เข้าแข่งขัน คนอื่นๆ ย่อมเป็นสิ่งที่ถวิลหา และเป็นชัยชนะที่รอคอย",
  subDescription: "",
  image: {
    bucket: "https://picsum.photos/500",
    key: null,
    region: null,
    identityId: null,
    level: null,
  },
  relatedBlogs: [],
  categoryIDs: "0001",
  blogCategoryID: "0001",
  blogCategory: {
    id: "0001",
    name: "เพศสัมพันธ์",
    description: "",
    createdAt: "",
    updatedAt: "",
  },
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  authorName: "K Thana",
  reviewedBy: "Dr.Anake",
  seoTitle: null,
  seoDescription: null,
  isFeature: null,
  status: null,
};

export const MOCK_BLOG_HTML = `
    <div>
      <p>คงปฏิเสธไม่ได้เลยว่า “ผม” เป็นส่วนหนึ่งที่ทำให้เรามีบุคลิกภาพที่ดีได้โดยไม่ต้องเติมแต่ง การมีสุขภาพผมที่ดีก็เหมือนมีชัยไปกว่าครึ่ง เมื่อเรามีอายุมากขึ้น ย่อมมีการเปลี่ยนแปลงมากมายรอบตัว เมื่อย้อนกลับไปดูรูปเก่าๆ จะเห็นได้ชัดว่าจากเดิมที่เคยมีเส้นผมที่ดกดำ กลับกลายเป็นผมร่วง ผมบางลงจึงเป็นปัญหาที่สร้าง ความหนักใจ ของใครหลายคน ไม่ว่าจะแต่งตัวแบบไหน ถ่ายรูปมุมใด ก็กังวลใจ ยิ่งมาตอกย้ำทำให้สูญเสียความมั่นใจ</p>
      <br />
      <img src='/images/mock/blog_1.png' class='w-full rounded-[12px]' />
      <br />
      <div>
        “ผมร่วง ผมบาง” มีโอกาสเกิดในผู้ชายได้มากกว่าผู้หญิง เมื่ออายุมากขึ้น ผมก็หลุดร่วงมากขึ้น และยิ่งบางลง และหลายๆคนมีความกังวลว่าหากผมร่วง หรือผมบางไปแล้ว เมื่อเข้ารับการรักษา นานเท่าไหร่ถึงจะเห็นผล
      </div>
      <br />
      
      <div>
        <p id="topic-1" class="heading6 font-bold">วงจรผมทำงานอย่างไร?</p>
        <p>ก่อนอื่นต้องทำความเข้าใจก่อนว่า วงจรผมของคนเรามีการเจริญเติบโตและถูกแบ่งออกเป็น 3 ระยะ</p>
      </div>

      <br />

      <div>
        <p class='font-bold'>ระยะเจริญเติบโต (Anagen) </p>
        <p>จะมีต่อมรากผม (hair Follicle)อยู่ลึกสุดในชั้นผิวหนัง เลือดจะมาเลี้ยงมาก เส้นผมจะสีเข้มและมีความหนาและเยอะสุด ประมาณ 90% ระยะนี้จะกินเวลานานที่สุด 3 ปี </p>
      </div>

      <br />

      <div>
        <p class='font-bold'>ระยะหยุดเจริญเติบโต (Catagen)</p>
        <p>จะเกิดขึ้นในช่วงสั้นๆ ประมาณ 3 อาทิตย์ ซึ่งมีเพียงไม่ถึง 1 %ที่เป็นผมระยะนี้  </p>
      </div>
      
      <br />

      <div>
        <p class='font-bold'>ระยะพัก (Telogen)</p>
        <p>หรือระยะสุดท้ายของผม ที่เส้นผมหยุดการเจริญ เส้นผมในระยะนี้กินเวลาประมาณ  3 เดือน แล้วจะหลุดร่วงไป โดยมีประมาณ 10-15 % ที่อยู่ในระยะนี้ หลังจากนั้นก็จะมีผมขึ้นมาใหม่กลับมา เริ่มต้นที่ระยะเจริญเติบโต (Anagen)  และวนเป็นวงจรไปเรื่อยๆ ต่อเนื่องไป</p>
      </div>
      <br />

      <img src='/images/mock/blog_2.png' class='rounded-[16px] m-auto' />

      <br />
      <p>ในคนที่เป็นโรคผมบางตามพันธุกรรมและฮอร์โมนเพศชาย ซึ่งเกิดในคนที่มีพันธุกรรมเสี่ยงเป็นพื้นฐานภาวะผมบาง เป็นผลมาจากฮอร์โมนเพศชาย ชนิดหนึ่งที่ชื่อ DHT (Dihydrotestosterone) ไปออกฤทธิ์ที่เส้นผม และเป็นตัวการที่ทำให้วงจรของเส้นผมเปลี่ยนไป โดยไปทำให้เส้นผมที่อยู่ ในระยะเจริญเติบโตกินเวลาสั้นลง ส่งผลให้ผมที่ควรจะมีเวลาให้เจริญเติบโตนานๆ กลับมีเวลาให้เจริญเติบโตที่สั้นลง จึงกลายเป็นผมที่มีขนาดเล็กลง บนหนังศีรษะ โดยจะเห็นเด่นชัดในตำแหน่งที่ได้รับผลกระทบจากฮอร์โมนเพศชายมาก ซึ่งก็คือตำแหน่งง่ามผม หรือตำแหน่งกลางกระหม่อมนั่นเอง </p>
      <br />

      <p>นอกจากนี้แล้วการที่ผมอยู่ในระยะเจริญเติบที่สั้นลง ยังส่งให้ เมื่อมองอย่างสัมพัทธ์ดูแล้วจะพบผมที่อยู่ในระยะพักตัวเป็น สัดส่วนที่ มากขึ้น ส่งผลให้บางครั้ง เราจึงอาจจะพบผมร่วงมากกว่าปกติได้ ในบางช่วงของโรค ซึ่งอาจจะมากกว่า 100 เส้นต่อวันได้เลยทีเดียว</p>
      <br />
      
      <div>
        <p id="topic-2" class="heading6 font-bold">มีภาวะผมบางทางพันธุกรรมและฮอร์โมนควรรักษาอย่างไร?</p>
        <p>แพทย์จะตรวจ สังเกต อาการและลักษณะของผม เริ่มต้นจะให้การรักษาด้วยยา ซึ่งมีทั้งชนิดพ่นที่ใช้เฉพาะที่ ไมนอกซิดิล(Minoxidil 5%) ช่วยสนับสนุนการงอกใหม่ของเส้นผม และยากินไฟแนสเตอร์ไรด์ (Finasteride) ที่ออกฤทธิ์ยับยั้งการทำงานของเอนไซม์ 5α-reductase ทำให้ฮอร์โมน DHT (Dihydrotestosterone) ที่บริเวณหนังศีรษะลดลง ลดการหลุดร่วง และช่วยสนับสนุนขึ้นใหม่ของเส้นผม ซึ่งทั้งสองชนิดเป็นยาที่ได้รับการรับรองจากองค์การอาหารและยาทั้งไทยและอเมริกา</p>
      </div>
      <br />
      
      <div>
        <p id="topic-3" class="heading6 font-bold">รักษาใช้เวลานานเท่าไหร่?</p>
        <p>ผลการรักษาจะขึ้นอยู่กับแต่ละบุคคลและความรุนแรงของโรค โดยส่วนใหญ่จะใช้เวลารักษาประมาณ 4-6 เดือน จึงจะเริ่มเห็นว่าอาการของผมดีขึ้น และควรใช้อย่างต่อเนื่องตามคำแนะนำ ของผู้เชี่ยวชาญหรือแพทย์  ผมหลุดร่วง ผมบางอาจทำลายความมั่นใจเพียงชั่วคราว เมื่อรู้และรักษาในแต่เนิ่น ๆ เพิ่มความมั่นใจกลับคืนมา คืนเส้นผมให้ดกดำกลับคืนมา hibro มีผู้เชี่ยวชาญและแพทย์เฉพาะด้านคอยให้คำปรึกษาปัญหาสุขภาพผมของคุณ</p>
      </div>
      <br />

      <div>
        <p class='font-bold'>References</p>
        <a href='#'><p>www.loremipsumdolor.com </p></a>
        <a href='#'><p>www.sitametconsecteturadipiscingelit.com</p></a>
        <a href='#'><p>www.dignissimluctuspellentesquelobortisornarenisl.com</p></a>
      </div>
    </div>
  `;

export const MOCK_REVIEW = {
  description:
    "Good service and good prices\nใช้งานสะดวกมากครับ ถ้าเว็บไซต์ซัพพอท browser บนโทรศัพท์ดีกว่านี้จะดีมากเลยครับ",
  rating: 4,
  name: "K. Thana S",
  date: new Date().toISOString(),
};

export const MOCK_HAIR_REVIEW = {
  name: "K. Thana S, 46",
  image:
    "https://as2.ftcdn.net/v2/jpg/02/94/57/61/1000_F_294576149_ozbaKEFP0AOwtcNc6xmStiqS8T50JmLy.jpg",
  description:
    "ได้ลองใช้มาเป็นระยะเวลากว่า 8 เดือนแล้ว ผลปรากฏว่าผมไม่ร่วงแล้วครับ และผมที่บางก็เริ่มมีผลงอกขึ้นมา อาจจะไม่เยอะเท่าไหร่ แต่ก็นับว่าดีมากแล้ว",
};

export const MOCK_HAIR_REVIEW_HL = {
  en: [
    {
      image: "/images/hlReview/1.webp",
      name: "K. Ae, Oral treatment",
      description:
        "Fast delivery, good to use, able to see real results and it helps reduce hair loss.",
    },
    {
      image: "/images/hlReview/2.webp",
      name: "Reten5 user",
      description:
        "I have tried it for more than 8 months and the results show that my hair does not fall out anymore. And the thinning hair begins to grow. It is maybe not much for now but it's already very good.",
    },
    {
      image: "/images/hlReview/3.webp",
      name: "K. Prin, GPO Minoxidil and Sterzar",
      description:
        "People around me can tell the hair is getting thicker, and most importantly, the little brother is still strong as usual. (Smile)",
    },
    {
      image: "/images/hlReview/4.webp",
      name: "Minoxidil and Harifin user",
      description:
        "I have been taking Harifin 5mg and Minoxidil (Nuhair 5%) for about 3 months. I was very happy then. I tried to stop using Minoxidil and only take the tablets, but didn't see much improvement so I went back to use together. It must be used concurrently.",
    },
  ],
  th: [
    {
      image: "/images/hlReview/1.webp",
      name: "K. Ae, ยารักษาชนิดรับประทาน",
      description: "ส่งเร็ว ใช้ดี เห็นผลจริง ช่วยลดผมร่วง",
    },
    {
      image: "/images/hlReview/2.webp",
      name: "Reten5 user",
      description:
        "ได้ลองใช้มาเป็นระยะเวลากว่า 8 เดือนแล้ว ผลปรากฏว่าผมไม่ร่วงแล้วครับ และผมที่บางก็เริ่มมีผลงอกขึ้นมา อาจจะไม่เยอะเท่าไหร่ แต่ก็นับว่าดีมากแล้ว",
    },
    {
      image: "/images/hlReview/3.webp",
      name: "K. Prin, GPO Minoxidil and Sterzar",
      description:
        "คนที่เห็นหัวเราบ่อยๆ ก็ทักว่ามีลูกผม ผมดกดำขึ้น และที่สำคัญน้องชายยังแข็งปกติ smile",
    },
    {
      image: "/images/hlReview/4.webp",
      name: "Minoxidil and Harifin user",
      description:
        "ผมกิน finasteride (Harifin 5 mg.) กับใช้ Minoxidil (นูแฮร์ 5%)ประมาณ 3 เดือน ผมเริ่มๆขึ้นมา ตอนนั้นดีใจมาก ๆ คิดว่า โอเค แล้วเลยเลิกใช้ Minoxidil เหลือกินอย่างเดียว รู้สึกผมไม่ขึ้นเท่าไหร่ บางๆเหมือนเดิม ช่วงนั้นใจเสียมาก คิดว่าไม่ได้ผลแล้ว แต่ก็ลองกลับมาใช้คู่เหมือนเดิม เห็นว่าผมกลับมาขึ้นแล้ว ต่อไปคงจะทิ้งไม่ได้แล้วล่ะ คงต้องใช้ควบคู่กันไป",
    },
  ],
};

export const MOCK_REVIEW_ED = {
  en: [
    {
      name: "K. Nakorn",
      rating: 5,
      description: `Reliable. Good service.\nGood service.`,
      date: dayjs(new Date(2022, 7, 17)).toISOString(),
    },
    {
      name: "K. Teerawat",
      rating: 4.5,
      description: `Treated premature ejaculation well.\nThank you very much because I've been trying to find a solution for a very long time. Doctor is really professional about the treatment.`,
      // date: dayjs("14/06/2022").toISOString()
      date: dayjs(new Date(2022, 5, 14)).toISOString(),
    },
    {
      name: "K. Kritsada",
      rating: 5,
      description: `I think it helped me.\nReally like the service. It helps.`,
      // date: dayjs("12/06/2022").toISOString()
      date: dayjs(new Date(2022, 5, 12)).toISOString(),
    },
    {
      name: "K. Toon",
      rating: 4,
      description: `It's convenient\nI don't have time to go see doctor. It's convenient`,
      // date: dayjs("11/06/2022").toISOString()
      date: dayjs(new Date(2022, 5, 11)).toISOString(),
    },
    {
      name: "Thomas",
      rating: 5,
      description: `Good staff\nRecommended by my doctor in hospital. Very good and friendly`,
      // date: dayjs("09/06/2022").toISOString()
      date: dayjs(new Date(2022, 5, 9)).toISOString(),
    },
    {
      name: "K. Pisit",
      rating: 5,
      description: `See from Facebook online\nI know Dr Oat from Nanake. There should be a prescription for 6 months or 1 year`,
      // date: dayjs("09/06/2022").toISOString()
      date: dayjs(new Date(2022, 5, 9)).toISOString(),
    },
    {
      name: "K. Chanachai",
      rating: 5,
      description: `Has credibility and medical knowledge\nHas credibility and medical knowledge. Good service. It will be even better if the app is developed more`,
      // date: dayjs("06/06/2022").toISOString()
      date: dayjs(new Date(2022, 5, 6)).toISOString(),
    },
  ],
  th: [
    {
      name: "K. Nakorn",
      rating: 5,
      description: `บริการน่าเชื่อถือ\nน่าเชื่อถือ ดี`,
      date: dayjs(new Date(2022, 7, 17)).toISOString(),
      from: "FB/IG",
    },
    {
      name: "คุณธีระวัฒน์",
      rating: 4.5,
      description: `รักษาหลั่งเร็วได้ผลดี\nรักษาอาการหลั่งเร็วได้สำเร็จ ขอบคุณมากๆเลยครับ เพราะผมพยายามหาวิธีแก้ไขมานานมากๆเลยครับ เป็นมืออาชีพเกี่ยวกับการรักษาจริงๆ ครับ`,
      date: dayjs(new Date(2022, 5, 14)).toISOString(),
      from: "FB/IG",
    },
    {
      name: "คุุณกฤษฎา",
      rating: 5,
      description: `สามารถช่วยได้\nคิดว่าน่าจะช่วยเหลือผมได้ครับ ชอบมากๆครับ`,
      date: dayjs(new Date(2022, 5, 12)).toISOString(),
      from: "google search",
    },
    {
      name: "คุณตูน",
      rating: 4,
      description: `สะดวกสบาย\nไม่มีเวลาพบแพทย์ สะดวกมากครับ`,
      date: dayjs(new Date(2022, 5, 11)).toISOString(),
      from: "FB/IG",
    },
    {
      name: "คุณโทมัส",
      rating: 5,
      description: `Good staff\nVery good and friendly`,
      date: dayjs(new Date(2022, 5, 9)).toISOString(),
      from: "Dr.Wongsawat",
    },
    {
      name: "คุณพิสิทธิ์",
      rating: 5,
      description: `รู้จักจากรายการของน้าเนค\nรู้จักหมอจู๋ จากน้าเน็ค น่าจะมีการสั่งยาแบบ 6 เดือน รึปีนึงนะคับ`,
      date: dayjs(new Date(2022, 5, 9)).toISOString(),
      from: "Nanake programe",
    },
    {
      name: "คุณชนะชัย",
      rating: 5,
      description: `ความน่าเชื่อถือ และความรู้ทางการแพทย์\nความน่าเชื่อถือ และความรู้ทางการแพทย์ บริการดีแล้วครับ หากพัฒนาได้ดีกว่านี้ก็จะยิ่งดีขึ้นไปครับผม`,
      date: dayjs(new Date(2022, 5, 6)).toISOString(),
      from: "FB/IG",
    },
  ],
};
